<template>
  <template v-if="isLinkType">
    <nuxt-link-locale
      v-if="href"
      :to="href"
      class="tw-text-primary-300 tw-font-semibold tw-underline"
      :class="$attrs.class"
    >
      <slot>
        <span class="tw-text-300 tw-leading-400">{{ btnLabel }}</span>
      </slot>
    </nuxt-link-locale>

    <button
      v-else
      class="tw-text-primary-300 tw-font-semibold tw-underline"
      :class="$attrs.class"
      @click="$emit('click')"
    >
      <slot>
        <span class="tw-text-300 tw-leading-400">{{ btnLabel }}</span>
      </slot>
    </button>
  </template>

  <template v-else>
    <nuxt-link-locale v-if="href" :to="href" class="tw-inline-block" :class="$attrs.class">
      <de-button :size="buttonSize" :label="btnLabel" />
    </nuxt-link-locale>

    <de-button v-else :size="buttonSize" :label="btnLabel" :class="$attrs.class" />
  </template>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { ButtonSizeOptions } from '~/shared/components/lib/button/button.entity';

const props = defineProps({
  href: {
    type: String,
    default: null,
  },
  buttonSize: {
    type: String as PropType<ButtonSizeOptions>,
    default: ButtonSizeOptions.medium,
  },
  type: {
    type: String as PropType<'button' | 'link'>,
    default: 'button',
  },
  label: {
    type: String,
    default: null,
  },
});

defineEmits(['click']);

const { t } = useI18n();

const isLinkType = computed(() => props.type === 'link');
const btnLabel = computed(() => props.label || t('common.buttons.viewMore'));
</script>
